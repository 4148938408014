var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"},[_c('md-card',[_c('ValidationObserver',{ref:"addEmoji"},[_c('md-card-content',[_c('ValidationProvider',{staticClass:"md-layout-item md-size-100",attrs:{"mode":"passive","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("За 1 gold")]),_c('md-input',{attrs:{"value":_vm.goldToSilver,"type":"number"},on:{"input":function (val) {
                    if (val) {
                      _vm.setConvert({
                        goldToSilver: val,
                        silverToGold: _vm.silverToGold,
                      });
                    }
                  }}}),_c('span',{staticClass:"md-suffix"},[_vm._v("silver валюты")])],1)]}}])}),_c('ValidationProvider',{staticClass:"md-layout-item md-size-100",attrs:{"mode":"passive","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("За 1 silver")]),_c('md-input',{attrs:{"value":_vm.silverToGold,"type":"number"},on:{"input":function (val) {
                    if (val) {
                      _vm.setConvert({
                        goldToSilver: _vm.goldToSilver,
                        silverToGold: val,
                      });
                    }
                  }}}),_c('span',{staticClass:"md-suffix"},[_vm._v("gold валюты")])],1)]}}])}),_c('div',{staticClass:"buttons-wrap justify-end"},[_c('md-button',{staticClass:"md-raised md-success",on:{"click":_vm.handleSend}},[_vm._v(" Обновить ")])],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }