<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <ValidationObserver ref="addEmoji">
          <md-card-content>
            <ValidationProvider
              v-slot="{ errors }"
              class="md-layout-item md-size-100"
              mode="passive"
              tag="div"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>За 1 gold</label>
                <md-input
                  :value="goldToSilver"
                  type="number"
                  @input="
                    (val) => {
                      if (val) {
                        setConvert({
                          goldToSilver: val,
                          silverToGold: silverToGold,
                        });
                      }
                    }
                  "
                />
                <span class="md-suffix">silver валюты</span>
              </md-field>
            </ValidationProvider>
            <ValidationProvider
              v-slot="{ errors }"
              class="md-layout-item md-size-100"
              mode="passive"
              tag="div"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>За 1 silver</label>
                <md-input
                  :value="silverToGold"
                  type="number"
                  @input="
                    (val) => {
                      if (val) {
                        setConvert({
                          goldToSilver: goldToSilver,
                          silverToGold: val,
                        });
                      }
                    }
                  "
                />
                <span class="md-suffix">gold валюты</span>
              </md-field>
            </ValidationProvider>
            <div class="buttons-wrap justify-end">
              <md-button class="md-raised md-success" @click="handleSend">
                Обновить
              </md-button>
            </div>
          </md-card-content>
        </ValidationObserver>
      </md-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
export default {
  computed: {
    ...mapState({
      goldToSilver: (state) => state.settings.goldToSilver,
      silverToGold: (state) => state.settings.silverToGold,
    }),
  },
  mounted() {
    this.getConvert();
  },
  methods: {
    ...mapActions(["getConvert", "sendConvert"]),
    ...mapMutations(["setConvert"]),
    async handleSend() {
      const response = await this.sendConvert();

      this.$notify({
        message: `Конвертация валюты${response ? "" : " не"} обновлена.`,
        icon: response ? "task_alt" : "do_not_disturb_alt",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: response ? "primary" : "secondary",
      });
    },
  },
};
</script>

<style scoped>
.buttons-wrap {
  display: flex;
  align-items: center;
}

.spin {
  margin-left: 20px;
}

.cancel-btn {
  margin-left: 10px !important;
}
.m-0 {
  margin: 0 !important;
}

.xmx-datepicker,
.w-full {
  width: 100%;
}
</style>
